<template>
  <div>
    <el-upload
      class='upload-demo'
      :on-change='handleChange'
      :on-success='importSuccess'
      :headers='uploadHeaders'
      :data='postData'
      name='file'
      :show-file-list='false'
      :file-list='file'
      :before-upload='beforeUpload'
      drag
      :action='uri'
      multiple>
      <i class='el-icon-upload'></i>
      <div class='el-upload__text'>将文件拖到此处，或<em>点击上传</em></div>
      <div class='el-upload__tip' slot='tip'></div>
    </el-upload>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'

export default {
  name: 'FileUploader',
  props: {
    uri: {
      type: String
    }
  },
  data() {
    return {
      file: [],
      uploadLoading: false,
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      postData: { lb_id: null },
      maxSize: 10//文件最大的 5 M
    }
  },
  methods: {
    // ======================= 导入相关 =====================
    handleChange(file) {
      this.file = [file]
    },
    beforeUpload(file) {
      //身份凭据
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()

      this.uploadLoading = true
      const isLt2M = file.size / 1024 / 1024 < this.maxSize
      if (!isLt2M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`)
        this.uploadLoading = false
      }
      return isLt2M
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('导入成功')
        this.$emit('imported',response.data)
      } else {
        this.$message.warning(response.message)
      }
    }
  }
}
</script>

<style scoped>

</style>