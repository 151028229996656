<template>
  <div>
    <div>
      <div class='table-search'>
        <el-form :model='searchCondition' inline size='mini'>
          <el-form-item label='文档名称'>
            <el-input v-model='searchCondition.title' placeholder='文档名称：模糊搜索' clearable></el-input>
          </el-form-item>
          <el-form-item label='归档人'>
            <el-input v-model='searchCondition.creator' placeholder='资料归档人：模糊搜索' clearable></el-input>
          </el-form-item>
          <el-form-item label='创建时间：'>
            <el-date-picker
              v-model='searchCondition.date_range'
              type='daterange'
              align='right'
              unlink-panels
              range-separator='至'
              start-placeholder='开始日期'
              end-placeholder='结束日期'
              value-format='yyyy-MM-dd'
              :picker-options='pickerOptions'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
            <el-button type='primary' icon='el-icon-upload' @click='handleUpload'
                       v-if='userPermissions.indexOf("kol_archive_import")>-1'>导入
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--    <el-row>-->
    <!--      <el-col :span='1.5'>-->
    <!--        <el-button icon='el-icon-delete' @click='handleBatchDelete'>删除</el-button>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <div class='default-table'>
      <el-table :data='dataList' :loading='loadingStatus' max-height='800' @sort-change='changeTableSort'>
        <el-table-column type='index' label='序号' width='60'></el-table-column>
        <el-table-column prop='title' label='文档名称' min-width='220'></el-table-column>
        <el-table-column prop='creator' label='归档人' align='center' min-width='120'></el-table-column>
        <el-table-column prop='created_at' label='创建时间' align='center' min-width='120'></el-table-column>
        <el-table-column prop='size_alias' label='文件大小' align='center' min-width='100'></el-table-column>
        <el-table-column label='操作' align='center' min-width='100'>
          <template slot-scope='{row}'>
            <el-button type='text' icon='el-icon-view' @click='detail(row)'
                       v-loading="detailLoading" v-if='userPermissions.indexOf("kol_archive_detail")>-1'>详情
            </el-button>
            &nbsp;
            &nbsp;
            <el-dropdown type='primary'>
              <span class='el-dropdown-link'>
                更多<i class='el-icon-arrow-down el-icon--right'></i>
              </span>
              <el-dropdown-menu slot='dropdown'>
                <el-dropdown-item>
                  <export-btn :ref='`refExportLink_${row.id}`' type='text'
                              :canExport='userPermissions.indexOf("kol_archive_export")>-1' lintTitle='导出'
                              @export='exportExcel(row)'></export-btn>
                </el-dropdown-item>
                <el-dropdown-item divided>
                  <el-button type='text' icon='el-icon-delete' @click='handleDelete(row)'
                             v-if='userPermissions.indexOf("kol_archive_delete")>-1'>删除
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!--    渲染分页-->
      <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                    @pagination='getList' />
    </div>
    <div>
      <tips title='注意事项：' :data='tipList'></tips>
    </div>

    <div>
      <el-dialog :title='dialogTitle' :visible.sync='open' fullscreen append-to-body>
        <div v-if='!form.id' style='width: 100%;text-align: center'>
          <FileUploader ref='kol_archive_import' uri='/admin_api/kol_archive/import' @imported='handleImport' />
        </div>
        <div v-else>
          <div style='text-align: center;'>
            <el-link type='primary' icon='el-icon-refresh' @click='getInfo(form.id)'><span
              style='font-size: 18px'>{{ form.title }}</span></el-link>
          </div>
          <el-row :gutter='15'>
            <el-col :span='4'>
              <h3>工作簿</h3>
              <div>
                <el-input placeholder='机构名（模糊搜索）'></el-input>
                <el-table :data='sheetList' highlight-current-row border>
                  <el-table-column label='序号' type='index' width='60'></el-table-column>
                  <el-table-column label='工作簿名' align='left' header-align='center'>
                    <template slot-scope='{row}'>
                      <el-link @click='changeSheet(row.title)'>{{ row.title }}</el-link>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
            </el-col>
            <el-col :span='20'>
              <div>
                <div style='display: flex'>
                  <h3> 当前工作簿：{{ currentTitle || '' }}</h3>
                  <div style='margin-left: 50px'>
                    <m-pagination :total.sync='sheetTotal' layout='total, sizes, prev, pager, next, jumper'
                                  :limit.sync='sheetPageData.page_size'
                                  :page.sync='sheetPageData.current_page' @pagination='getSheetData'></m-pagination>
                  </div>
                </div>
                <div class='excel-box'>
                  <div v-for='(row,index) in sheetRows' :key='index' class='row'>
                    <!--                <div style='line-height: 30px;border: #ff8108 1px solid'>{{row}}</div>-->
                    <div class='cell' v-for='(cell,j) in row' :key='`${index}_${j}`'>{{ cell }}</div>
                  </div>
                </div>


              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import FileUploader from '@/components/import/FileUploader'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'KoLArchiveList',
  components: { ExportBtn, FileUploader },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loadingStatus: false,
      searchCondition: { title: null, date_range: null, creator: null },
      dataList: [
        {
          title: '缇苏koc资源库【个人博主版】.xlsx',
          creator: '季建贵',
          created_at: '2023/10/24',
          size_alias: '9M',
          'type': 'xlsx'
        },
        {
          title: '缇苏koc资源库【机构合作版】.xlsx',
          creator: '季建贵',
          created_at: '2023/10/24',
          size_alias: '6M',
          'type': 'xlsx'
        }
      ],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      orderSort: { 'id': 'desc' },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      open: false,
      dialogTitle: '归档信息',
      form: {},
      sheetList: [
        // { title: '行动派小红书koc' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '太氪传媒刊例' },
        // { title: '行动派小红书kol' }
      ],
      currentTitle: null,
      sheetPageData: { current_page: 1, page_size: 20 },
      sheetTotal: 0,
      sheetRows: [],
      tipList: [
        '表格根据每个导入的数据表显示'
      ],
      detailLoading: false
    }
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      this.dataList = []
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getKolArchiveList(searchCondition)
      this.dataList = list
      this.total = pages.total
      this.offset = pages.offset
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      setTimeout(() => {
        this.loadingStatus = false
      }, 500)
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.creator) {
        condition.creator = this.searchCondition.creator
      }
      if (this.searchCondition.title) {
        condition.title = this.searchCondition.title
      }
      if (this.searchCondition.date_range && this.searchCondition.date_range.length === 2) {
        condition.date_range = this.searchCondition.date_range

      }
      return condition
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    async getInfo(id) {
      let { info } = await this.$api.getKolArchiveInfo(id)
      // this.form = { ...info }
      let sheets = info.excel ? info.excel.sheets : []
      this.sheetList = sheets.map((title) => {
        return { title: title }
      })
    },
    changeSheet(title) {
      this.currentTitle = title
      this.sheetPageData.current_page = 1
      this.getSheetCount()
      this.getSheetData()
    },
    async getSheetCount() {
      let params = { id: this.form.id, sheet_title: this.currentTitle }
      Object.assign(params, this.sheetPageData)

      let { total } = await this.$api.getKolArchiveSheetCount(params)
      this.sheetTotal = total
    },
    async getSheetData() {
      let params = { id: this.form.id, sheet_title: this.currentTitle }
      Object.assign(params, this.sheetPageData)

      let { info } = await this.$api.getKolArchiveSheetContent(params)
      this.sheetRows = info.excel ? info.excel.content : []
    },
    async detail(row) {
      this.detailLoading = true
      this.form = { ...row }
      await this.getInfo(row.id)
      this.open = true
      this.dialogTitle = '归档信息详情'
      this.detailLoading = false
    },
    handleAdd() {
      this.form = []
      this.open = true
      this.dialogTitle = '添加归档信息'
    },
    async handleImport(val) {
      this.form = val ? val.info : {}
      await this.getInfo(this.form.id)
      // this.handleQuery()
    },
    async handleDelete(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.delKolArchive(row.id)
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleUpload() {
      this.form = {}
      this.open = true
      this.dialogTitle = '上传归档信息'
    },
    async exportExcel(row) {
      try {
        let name = `${row.title}}`
        let response = await this.$api.exportKolArchive(row.id)
        if (this.$refs[`refExportLink_${row.id}`])
          this.$refs[`refExportLink_${row.id}`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>
.excel-box {
  overflow-x: scroll;
  overflow-y: scroll;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  /*border: #00feff 1px solid;*/
}

.cell {
  border: #ffeaf1 1px solid;
  line-height: 20px;
  max-height: 60px;
  width: 160px;
  flex: auto;
  text-align: center;
  vertical-align: middle;
  overflow-y: scroll;
  overflow-x: scroll;
}
</style>

<style>
.el-dropdown-link {
  cursor: pointer;
  color: #ff3176;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
